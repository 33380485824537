import axios from 'axios';
import {Cookies} from 'react-cookie';
import {navigate} from 'gatsby'
import { notify } from 'react-notify-toast';

var defaultHeader = {
  'Content-Type': 'application/json',
  // token :
};

var postMethodHeaders = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

let api_url = 'https://app.121islam.com/api';
//let api_url = 'http://localhost:8080';

axios.interceptors.request.use(
  config => {

    if(config.url.indexOf('/auth/')===-1 && config.url.indexOf('/firebase/tokens')===-1){
      let cookies =  new Cookies();
      //let token = cookies.get('token');
      let token = window.localStorage.getItem('token');
      config.headers["Authorization"] = `Bearer ${token}`
    }
    else if(config.url.indexOf('/auth/switchprofiles')>0 || config.url.indexOf('/auth/firebase')>0 ){
      let cookies =  new Cookies();
      //let token = cookies.get('token');
      let token = window.localStorage.getItem('token');
      while(token===undefined){
        token = window.localStorage.getItem('token');
      }
      if(token){
        config.headers["Authorization"] = `Bearer ${token}`
      }
      
    }
    return config;
  },
  error => {
    return error;
  },
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    let { response } = error;
    //console.log(response);
    if(response.status==403)
      notify.show("You are not allowed to access this portal. Please contact Support", "error",10000000)
    // eslint-disable-next-line
    //throw { ...response, response: error.response, status: response.status };
  }
);

export const getMethod = async (url, header, params, domainName) => {
  try {
    const response = await axios({
      method: 'get',
      url: domainName ? domainName + url : api_url + url,
      params: params ? params : {  },
      headers: header ? header : defaultHeader,
    });
    return response;
  } catch (error) {
     //console.error(error.status);
     if(error.status===401){
      //navigate('/?v='+window.localStorage.getItem('appVersion'));
     }
    
    return error;
  }
};

export const deleteMethod = async (
  url,
  requestData,
  params,
  header,
  domainName
) => {
  try {
    const response = await axios({
      method: "delete",
      url: domainName ? domainName + url : api_url + url,
      data: requestData,
      params: params ? params : {  },
      headers: header ? header : defaultHeader
    });
    return response;
  } catch (error) {
    throw error;
  }
};


export const postMethod = async (url, requestData, params, header, domainName) => {
  try {
    const response = await axios({
      method: 'post',
      url: domainName ? domainName + url : api_url + url,
      data: requestData,
      params: params ? params : {  },
      //headers: header ? header : defaultHeader,
    });

    return response;
  } catch (error) {
    
    return error;
  }
};

export const putMethod = async (url, requestData, params, header, domainName) => {
  try {
    const response = await axios({
      method: 'put',
      url: domainName ? domainName + url : api_url + url,
      data: requestData,
      params: params ? params : {  },
      headers: header ? header : defaultHeader,
    });
    //console.log(response);
    return response;
  } catch (error) {
     //console.log(error.response.data);
    return error;
  }
};

export const putMethodJSON = async (url, requestData, params, header, domainName) => {
  try {
    const response = await axios({
      method: 'put',
      url: domainName ? domainName + url : api_url + url,
      data: requestData,
      params: params ? params : {  },
      headers: header ? header : defaultHeader,
    });

    return response;
  } catch (error) {
    //  console.error(error);
   
    return error;
  }
};

export const postMethodJSON = async (url, requestData, params, header, domainName) => {
  try {
    const response = await axios({
      method: 'post',
      url: domainName ? domainName + url : api_url + url,
      data: requestData,
      params: params ? params : {  },
      headers: header ? header : defaultHeader,
      transformRequest: jsonData => transformRequest(jsonData)
    });

    return response;
  } catch (error) {
    //  console.error(error);
   
    return error;
  }
};

const transformRequest = (jsonData = {}) =>
  Object.entries(jsonData)
    .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
    .join('&');

